// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-accessibility-statement-mdx": () => import("./../../../../src/Accessibility Statement.mdx" /* webpackChunkName: "component---src-accessibility-statement-mdx" */),
  "component---src-change-language-mdx": () => import("./../../../../src/Change Language.mdx" /* webpackChunkName: "component---src-change-language-mdx" */),
  "component---src-click-collect-mdx": () => import("./../../../../src/Click & Collect.mdx" /* webpackChunkName: "component---src-click-collect-mdx" */),
  "component---src-configure-a-gps-location-mdx": () => import("./../../../../src/Configure a GPS Location.mdx" /* webpackChunkName: "component---src-configure-a-gps-location-mdx" */),
  "component---src-configure-account-push-notifications-mdx": () => import("./../../../../src/Configure Account Push Notifications.mdx" /* webpackChunkName: "component---src-configure-account-push-notifications-mdx" */),
  "component---src-configure-ad-hoc-push-notifications-mdx": () => import("./../../../../src/Configure Ad-Hoc Push Notifications.mdx" /* webpackChunkName: "component---src-configure-ad-hoc-push-notifications-mdx" */),
  "component---src-configure-app-authentication-mdx": () => import("./../../../../src/Configure App Authentication.mdx" /* webpackChunkName: "component---src-configure-app-authentication-mdx" */),
  "component---src-configure-barcode-self-check-mdx": () => import("./../../../../src/Configure Barcode Self-Check.mdx" /* webpackChunkName: "component---src-configure-barcode-self-check-mdx" */),
  "component---src-configure-click-collect-mdx": () => import("./../../../../src/Configure Click & Collect.mdx" /* webpackChunkName: "component---src-configure-click-collect-mdx" */),
  "component---src-configure-dynamic-tabs-mdx": () => import("./../../../../src/Configure Dynamic Tabs.mdx" /* webpackChunkName: "component---src-configure-dynamic-tabs-mdx" */),
  "component---src-configure-minimum-app-version-mdx": () => import("./../../../../src/Configure Minimum App Version.mdx" /* webpackChunkName: "component---src-configure-minimum-app-version-mdx" */),
  "component---src-configure-patron-account-options-mdx": () => import("./../../../../src/Configure Patron Account Options.mdx" /* webpackChunkName: "component---src-configure-patron-account-options-mdx" */),
  "component---src-configure-rfid-self-check-mdx": () => import("./../../../../src/Configure RFID Self-Check.mdx" /* webpackChunkName: "component---src-configure-rfid-self-check-mdx" */),
  "component---src-configure-stripe-payments-mdx": () => import("./../../../../src/Configure Stripe Payments.mdx" /* webpackChunkName: "component---src-configure-stripe-payments-mdx" */),
  "component---src-configure-ui-colors-mdx": () => import("./../../../../src/Configure UI Colors.mdx" /* webpackChunkName: "component---src-configure-ui-colors-mdx" */),
  "component---src-create-a-content-item-mdx": () => import("./../../../../src/Create a Content Item.mdx" /* webpackChunkName: "component---src-create-a-content-item-mdx" */),
  "component---src-create-a-self-service-tab-mdx": () => import("./../../../../src/Create a Self-Service Tab.mdx" /* webpackChunkName: "component---src-create-a-self-service-tab-mdx" */),
  "component---src-create-a-tab-mdx": () => import("./../../../../src/Create a Tab.mdx" /* webpackChunkName: "component---src-create-a-tab-mdx" */),
  "component---src-create-a-user-mdx": () => import("./../../../../src/Create a User.mdx" /* webpackChunkName: "component---src-create-a-user-mdx" */),
  "component---src-create-dynamic-home-tabs-mdx": () => import("./../../../../src/Create Dynamic Home Tabs.mdx" /* webpackChunkName: "component---src-create-dynamic-home-tabs-mdx" */),
  "component---src-creating-channels-video-mdx": () => import("./../../../../src/Creating Channels Video.mdx" /* webpackChunkName: "component---src-creating-channels-video-mdx" */),
  "component---src-creating-content-video-mdx": () => import("./../../../../src/Creating Content Video.mdx" /* webpackChunkName: "component---src-creating-content-video-mdx" */),
  "component---src-creating-users-video-mdx": () => import("./../../../../src/Creating Users Video.mdx" /* webpackChunkName: "component---src-creating-users-video-mdx" */),
  "component---src-customize-highlights-on-home-screen-mdx-md": () => import("./../../../../src/Customize Highlights on Home Screen.mdx.md" /* webpackChunkName: "component---src-customize-highlights-on-home-screen-mdx-md" */),
  "component---src-delete-a-channel-mdx": () => import("./../../../../src/Delete a Channel.mdx" /* webpackChunkName: "component---src-delete-a-channel-mdx" */),
  "component---src-delete-a-content-item-mdx": () => import("./../../../../src/Delete a Content Item.mdx" /* webpackChunkName: "component---src-delete-a-content-item-mdx" */),
  "component---src-delete-a-content-items-schedule-mdx": () => import("./../../../../src/Delete a Content Item's Schedule.mdx" /* webpackChunkName: "component---src-delete-a-content-items-schedule-mdx" */),
  "component---src-delete-a-tab-mdx": () => import("./../../../../src/Delete a Tab.mdx" /* webpackChunkName: "component---src-delete-a-tab-mdx" */),
  "component---src-delete-a-user-mdx": () => import("./../../../../src/Delete a User.mdx" /* webpackChunkName: "component---src-delete-a-user-mdx" */),
  "component---src-edit-a-channel-mdx": () => import("./../../../../src/Edit a Channel.mdx" /* webpackChunkName: "component---src-edit-a-channel-mdx" */),
  "component---src-edit-a-mobile-app-mdx": () => import("./../../../../src/Edit a Mobile App.mdx" /* webpackChunkName: "component---src-edit-a-mobile-app-mdx" */),
  "component---src-edit-a-tab-mdx": () => import("./../../../../src/Edit a Tab.mdx" /* webpackChunkName: "component---src-edit-a-tab-mdx" */),
  "component---src-edit-languages-mdx": () => import("./../../../../src/Edit Languages.mdx" /* webpackChunkName: "component---src-edit-languages-mdx" */),
  "component---src-edit-the-home-screen-tab-mdx": () => import("./../../../../src/Edit the Home Screen Tab.mdx" /* webpackChunkName: "component---src-edit-the-home-screen-tab-mdx" */),
  "component---src-faq-mdx": () => import("./../../../../src/FAQ.mdx" /* webpackChunkName: "component---src-faq-mdx" */),
  "component---src-generate-an-activity-report-mdx": () => import("./../../../../src/Generate an Activity Report.mdx" /* webpackChunkName: "component---src-generate-an-activity-report-mdx" */),
  "component---src-getting-started-mdx": () => import("./../../../../src/Getting Started.mdx" /* webpackChunkName: "component---src-getting-started-mdx" */),
  "component---src-helpful-apple-links-mdx": () => import("./../../../../src/Helpful Apple Links.mdx" /* webpackChunkName: "component---src-helpful-apple-links-mdx" */),
  "component---src-link-content-to-a-tab-mdx": () => import("./../../../../src/Link Content To a Tab.mdx" /* webpackChunkName: "component---src-link-content-to-a-tab-mdx" */),
  "component---src-linked-accounts-mdx": () => import("./../../../../src/Linked Accounts.mdx" /* webpackChunkName: "component---src-linked-accounts-mdx" */),
  "component---src-my-lists-mdx": () => import("./../../../../src/My Lists.mdx" /* webpackChunkName: "component---src-my-lists-mdx" */),
  "component---src-navigation-tutorial-video-mdx": () => import("./../../../../src/Navigation Tutorial Video.mdx" /* webpackChunkName: "component---src-navigation-tutorial-video-mdx" */),
  "component---src-notifications-mdx": () => import("./../../../../src/Notifications.mdx" /* webpackChunkName: "component---src-notifications-mdx" */),
  "component---src-open-the-staff-dashboard-mdx": () => import("./../../../../src/Open the Staff Dashboard.mdx" /* webpackChunkName: "component---src-open-the-staff-dashboard-mdx" */),
  "component---src-organize-tabs-mdx": () => import("./../../../../src/Organize Tabs.mdx" /* webpackChunkName: "component---src-organize-tabs-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pin-a-tab-mdx": () => import("./../../../../src/Pin a Tab.mdx" /* webpackChunkName: "component---src-pin-a-tab-mdx" */),
  "component---src-privacy-policy-mdx": () => import("./../../../../src/Privacy Policy.mdx" /* webpackChunkName: "component---src-privacy-policy-mdx" */),
  "component---src-reading-tracker-awards-mdx": () => import("./../../../../src/Reading Tracker - Awards.mdx" /* webpackChunkName: "component---src-reading-tracker-awards-mdx" */),
  "component---src-reading-tracker-delete-reviews-mdx": () => import("./../../../../src/Reading Tracker - Delete Reviews .mdx" /* webpackChunkName: "component---src-reading-tracker-delete-reviews-mdx" */),
  "component---src-reading-tracker-mdx": () => import("./../../../../src/Reading Tracker.mdx" /* webpackChunkName: "component---src-reading-tracker-mdx" */),
  "component---src-reading-tracker-reviews-mdx": () => import("./../../../../src/Reading Tracker - Reviews.mdx" /* webpackChunkName: "component---src-reading-tracker-reviews-mdx" */),
  "component---src-releases-mdx": () => import("./../../../../src/Releases.mdx" /* webpackChunkName: "component---src-releases-mdx" */),
  "component---src-schedule-a-content-item-mdx": () => import("./../../../../src/Schedule a Content Item.mdx" /* webpackChunkName: "component---src-schedule-a-content-item-mdx" */),
  "component---src-supported-devices-operating-systems-mdx": () => import("./../../../../src/Supported Devices & Operating Systems.mdx" /* webpackChunkName: "component---src-supported-devices-operating-systems-mdx" */),
  "component---src-supported-vendors-mdx": () => import("./../../../../src/Supported Vendors.mdx" /* webpackChunkName: "component---src-supported-vendors-mdx" */)
}

